<template>
  <select v-model="locale" class="button btn-outline btn-small input-small" aria-label="Language" @change="onChange">
    <option value="en">en</option>
    <option value="es">es</option>
    <option value="fr">fr</option>
  </select>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { locale } = useI18n()

function onChange() {
  localStorage.locale = locale.value
}
</script>

<style scoped>
/* TODO this should be doable by just overriding the button border color */
select {
  box-shadow: inset 0 0 0 1px var(--color-language-selector-border);
}
</style>
