<template>
  <RouterView />
</template>

<script setup>
import { provide } from 'vue'
import { useI18n } from 'vue-i18n'
import '@neonmoose/alma/dist/style.css'
import { defineGlobalRules } from '@neonmoose/alma/validation'
import { t } from '@/i18n.js'
import '@neonmoose/display-icons/style.css'

defineGlobalRules({ t })

const { locale } = useI18n()

provide('locale', locale)
</script>

<style>
@import '@/styles/app.css';
@import '@/styles/transitions.css';
</style>
