import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'

const router = createRouter({
  history: createWebHistory(),
  routes: [...setupLayouts(generatedRoutes), { path: '/', redirect: '/guided-shopping/basic' }],
})

// Navigation guard to handle trailing slashes
router.beforeEach((to) => {
  if (to.path !== '/' && to.path.endsWith('/')) {
    return { path: to.path.slice(0, -1), query: to.query, hash: to.hash, replace: true }
  }
})

export default router
