const __pages_import_0__ = () => import("/src/pages/guided-shopping/basic.vue");
const __pages_import_1__ = () => import("/src/pages/guided-shopping/basic/timing.vue");
const __pages_import_2__ = () => import("/src/pages/guided-shopping/basic/offer-benefits.vue");
const __pages_import_3__ = () => import("/src/pages/guided-shopping/basic/industry.vue");
const __pages_import_4__ = () => import("/src/pages/guided-shopping/basic/how-many-employees.vue");
const __pages_import_5__ = () => import("/src/pages/guided-shopping/basic/guided-shopping-store.js");
const __pages_import_6__ = () => import("/src/pages/guided-shopping/basic/current-payroll-provider.vue");
const __pages_import_7__ = () => import("/src/pages/guided-shopping/basic/contact-form.vue");

const routes = [{"name":"guided-shopping-basic","path":"/guided-shopping/basic","component":__pages_import_0__,"children":[{"name":"guided-shopping-basic-timing","path":"timing","component":__pages_import_1__,"props":true},{"name":"guided-shopping-basic-offer-benefits","path":"offer-benefits","component":__pages_import_2__,"props":true},{"name":"guided-shopping-basic-industry","path":"industry","component":__pages_import_3__,"props":true},{"name":"guided-shopping-basic-how-many-employees","path":"how-many-employees","component":__pages_import_4__,"props":true},{"name":"guided-shopping-basic-guided-shopping-store","path":"guided-shopping-store","component":__pages_import_5__,"props":true},{"name":"guided-shopping-basic-current-payroll-provider","path":"current-payroll-provider","component":__pages_import_6__,"props":true},{"name":"guided-shopping-basic-contact-form","path":"contact-form","component":__pages_import_7__,"props":true}],"props":true,"redirect":"/guided-shopping/basic/how-many-employees"}];

export default routes;