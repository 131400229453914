import { createApp } from 'vue'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import App from './App.vue'
import i18n from './i18n.js'
import safeHtml from './plugins/safe-html.js'
import router from './router.js'
import './datadog.js'

const app = createApp(App)

app.use(autoAnimatePlugin)
app.use(i18n)
app.use(router)
app.use(safeHtml)

app.mount('#app')
