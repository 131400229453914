import { datadogRum } from '@datadog/browser-rum'

if (import.meta.env.MODE === 'prod') {
  datadogRum.init({
    applicationId: 'eebdec70-ba98-420a-ab3f-43eaa5c35a62',
    clientToken: 'pube366811f320209be75f615079b4def00',
    site: 'datadoghq.com',
    service: 'guided-shopping',
    env: import.meta.env.MODE,
    // eslint-disable-next-line no-undef
    version: __APP_VERSION__,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}
